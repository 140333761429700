@import "../../themes/generated/variables.additional.scss";

.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;

  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}

.image-container {
  overflow: hidden;
  //border-radius: 50%;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin: 0 4px;
  //border: 1px solid rgba(0, 0, 0, 0.1);
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  .user-image {
    width: 100%;
    height: 100%;
  }
}

.conversation-item {

  //  style={{background: `url(${user.avatarUrl}) no-repeat #fff`, backgroundSize: 'cover'}}
  &.bot {
    background-color: #fff;
    .user-image {
      background: url(/assets/robot-icon.png) no-repeat;
      background-size: cover;
    }
  }

  &.user {
    .user-image {
      background: url(https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/07.png) no-repeat;
      background-size: cover;
    }
  }

}

.conversation * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}

.conversation-item {
  display: flex;
  padding: 1.5rem 1.5rem 1.5rem 1rem;
  margin-bottom: 1rem;

  &.bot {
    background-color: #fff;
  }

  &.user {
    background-color: #F9F9F8;
  }

  .conversation-item-text {
    margin-left: 1rem;
    padding-top: 3px;
    h1, h2, h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  img {
    border-radius: 5px;
    max-height: 20vh;
  }
}

.user-input {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .input-wrapper {
    display: flex;
    align-items: flex-end;

    .dx-textarea {
      flex-grow: 2;
      border-color: $base-border-color;
      border-radius: 0;
      padding: 5px;
      font-size: 1rem;
      margin-right: 1rem;
    }

    .dx-button {
      background-color: $base-accent !important;
      height: 45px;

      &:hover {
        opacity: 0.9;
      }

      .dx-button-text {
        font-weight: 700;
      }
    }

    #attach-file-button {
      background-color: #F9F9F8 !important;
      width: 45px;
      margin-right: 1rem;
      box-shadow: none;
    }
  }

}

.home-height-stretch {
  height: 100%;
}

#sendButton .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

#imagePreviewPopup.adjust-size {
  max-width: 100%;
  max-height: 67vh;
}

.attachments {
  // border: 2px solid #F9F9F8;
  // border-bottom: 0;
  // padding-left: 2rem;

  .dx-gallery {

    .dx-gallery-item {
      margin-left: 0 !important;
      margin-right: 1rem;
    }

    .dx-gallery-nav-button-next, .dx-gallery-nav-button-prev {
      width: initial;
    }

    .dx-gallery-nav-button-next::after, 
    .dx-gallery-nav-button-prev::after {
      width: 2rem;
      height: calc(100% - 15px);
      background-color: #eee !important;
      border: 1px solid #eee;
      // border-radius: 50%; */
      // top: 50%; */
      // margin-top: -16px;
    }
    .dx-gallery-nav-button-next::after {
      right: 0;
    }
    .dx-gallery-nav-button-prev::after {
      left: 0;
    }

    .dx-gallery-nav-button-next::before {
      right: 0;
    }
    .dx-gallery-nav-button-prev::before {
      left: 0;
    }
    .dx-gallery-nav-button-next::before,
    .dx-gallery-nav-button-prev::before {
      display: block;
      width: 32px;
      top: 50%;
      margin-top: -16px;
      font-size: 24px;
      color: #000;
    }
  }
}