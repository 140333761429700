@import "./themes/generated/variables.base.scss";

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: #fff;
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    // flex-grow: 1;
    // display: flex;
    height: 100%;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .content-block {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      width: 100%;
      // height: 100%; // S.I. it causes problems with the layout everywhere (except the home page)
      max-width: 840px;
      display: flex;
      flex-direction: column;

      .dx-card {
        box-shadow: none;
        flex-grow: 1;
        overflow: auto;

        .dx-card-header {
          background: #fff;
          padding: 1rem;
          border-bottom: 1px solid #DAD5D1;
          position: fixed;
          width: calc(100% - 60px);
          left: 60px;

          .dx-card-header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
          }
        }

        .dx-card-block {
          margin-top: 5rem;
        }
      }
    }
  }

  .dx-drawer-opened {

    .dx-card-header {
      width: calc(100% - 300px) !important;
      left: 300px !important;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    margin-top: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 60px)
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    // display: flex;
    // flex-direction: column;
    height: 100%;
  }
}

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.dx-button {
  border-radius: 0 !important;
  background-color: transparent !important;
}
