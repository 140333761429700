@import "../../themes/echogpt.variables.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  // .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
  //   color: $base-accent;
  // }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 1rem;

  // .screen-x-small & {
  //   padding: 0 20px;
  // }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 60px;
}

.header-title {
  .dx-item-content {
    font-size: 18px;
    color: #979797;
    padding: 0 0 0 1rem;
    margin: 0;
  }
}

.header-logo img {
  height: 30px;
  //margin-left: 10px;
  //filter: grayscale(1);
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
