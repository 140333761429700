@import "../../themes/generated/variables.base.scss";

.single-card {

  .dx-card {
    width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 30px;
    background-color: #F1EFEE;
    box-shadow: none;
    border-radius: 0;
    text-align: center;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;
      text-align: center;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }

    .dx-button {
      background-color: $base-accent !important;
      color: #fff;
      padding: 8px 16px;

      &:hover {
        opacity: 0.9;
      }

      .dx-button-text {
        font-weight: 700;
      }
    }
  }
}
