@import "../../themes/generated/variables.base.scss";

.reset-password-form {
  .dx-texteditor.dx-editor-filled {
    background-color: #fff;
    border-color: #DAD5D1;
    border-radius: 0;
  }

  .submit-button {
    margin-top: 10px;
  }

  .login-link {
    font-size: 14px;

    a {
      color: #000;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
