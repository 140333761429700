@import "../../themes/generated/variables.base.scss";

.login-form {
  text-align: center;

  .dx-texteditor.dx-editor-filled {
    background-color: #fff;
    border-color: #DAD5D1;
    border-radius: 0;
  }

  .link {
    font-size: 14px;

    a {
      color: #000;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

  .dx-layout-manager .dx-field-item {
    padding: 8px 0 !important;
  }
}
